import formatTime from '@/helpers/formatTime'
import { formatTimeByMS, timerCounterFormatByMS } from '@/helpers/timeUtils'
import Vue from 'vue'
import formatDate from '@/helpers/formatDate'

Vue.filter('dateFormat', (date, withTime = false, emptyValue = undefined) => {
  return formatDate(date, withTime, emptyValue)
})
Vue.filter(
  'timeFormat',
  (date, withTime = false, emptyValue = undefined, full = false) => {
    return formatTime(date, withTime, emptyValue, full)
  }
)
Vue.filter('timeFormatByMS', (ms = 0, full = false) => {
  return formatTimeByMS(ms, full)
})
Vue.filter('timerCounterFormatByMS', (ms = 0) => {
  return timerCounterFormatByMS(ms)
})
