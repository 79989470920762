<template>
  <transition name="modal">
    <!--@mousedown="onMousedown"-->
    <!--@mouseup="onMouseup"-->
    <div
      v-if="isOpen"
      :data-modal-name="name"
      class="modal"
      @mousedown="onMousedown"
      @mouseup="onMouseup"
      :style="`z-index: ${91 + currentIndex}`"
      :class="{
        _current: isCurrent,
        '_not-current': !isCurrent,
        '_full-height': fullHeight,
        '_small-title': passThroughProps?.smallTitle,
      }"
    >
      <div class="modal__content" ref="content">
        <div class="modal__head">
          <BaseSvg
            v-if="icoFormatted"
            :name="icoFormatted.name"
            :width="icoFormatted.width"
            :height="icoFormatted.height"
            class="modal__title-ico"
          />
          <h2 class="modal__title">
            <!--
            Get title priority:
            - get title from props argument in vModal.open(..., props) ,
            - then from title argument in vModal.open(..., ..., title),
            - then from v-modal component prop - title
            -->
            <slot
              v-bind="{
                ...passThroughProps,
                title:
                  passThroughProps?.title || currentModalObj?.title || title,
              }"
              name="title"
            >
              {{ passThroughProps?.title || currentModalObj?.title || title }}
            </slot>
          </h2>
          <button
            type="button"
            class="modal__close link"
            @click.stop.prevent="close"
          >
            <BaseSvg name="ico-close" />
          </button>
        </div>
        <component :is="'div'" class="modal__main" :key="refreshKey">
          <slot
            v-bind="passThroughProps"
            :title="undefined"
            :setFullHeight="setFullHeight"
            >No Data
          </slot>
        </component>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    ico: {
      type: [Object, String],
    },
    refreshTriggerPropKey: {
      type: String,
      default() {
        return 'id'
      },
    },
    noNeedConfirm: Boolean,
  },
  data() {
    return {
      pressTarget: false,
      fullHeight: false,
      refreshKey: 1,
    }
  },
  computed: {
    lastModalsNames() {
      return this.$get(this.$root, `lastModalsNames`, [])
    },
    isOpen() {
      return this.lastModalsNames.includes(this.name)
    },
    currentModalObj() {
      return this.$get(this.$root, `modals[${this.name}]`, {})
    },
    passThroughProps() {
      return this.$get(this.currentModalObj, 'props', {})
    },
    icoFormatted() {
      let ico = null

      if (typeof this.ico === 'string') {
        ico = {
          name: this.ico,
        }
      } else {
        ico = this.ico
      }

      if (typeof ico !== 'object') return null

      // if (!ico.width) ico.width = 20
      // if (!ico.height) ico.height = 20

      return ico
    },
    isCurrent() {
      return this.name === this.lastModalsNames[this.lastModalsNames.length - 1]
    },
    currentIndex() {
      const index = this.lastModalsNames.findIndex((item) => item === this.name)
      return index < 0 ? 0 : index
    },
    watchData() {
      return this.$get(
        this,
        `currentModalObj.props.${this.refreshTriggerPropKey}`,
        null
      )
    },
  },
  methods: {
    setFullHeight(val) {
      this.fullHeight = val
    },
    onMousedown(event) {
      this.pressTarget = event.target.contains(this.$refs.content)
    },
    onMouseup(event) {
      if (this.pressTarget && event.target.contains(this.$refs.content)) {
        const noNeedConfirm =
          this.noNeedConfirm || this.passThroughProps?.noNeedConfirm

        const buttons = Array.from(
          this.$el?.querySelector('.modal__main')?.querySelectorAll('button') ??
            []
        )

        const buttonNames = [
          'save',
          'cancel',
          'confirm',
          'done',
          'apply',
          'add',
          'send',
          'create',
        ]

        const maybeNeedConfirm = buttons.some((btn) => {
          const currentName = btn.textContent.trim().toLocaleLowerCase()
          return (
            (buttonNames.includes(currentName) ||
              currentName
                .split(' ')
                .some((part) => buttonNames.includes(part))) &&
            isVisible(btn)
          )
        })

        if (noNeedConfirm || !maybeNeedConfirm) {
          this.close()
        } else {
          this.$vAccept.open({
            title: 'Confirm Closing',
            text: 'Are you sure you want to close this modal?',
            onAccept: () => {
              this.close()
            },
          })
        }
      }
    },
    close() {
      this.$vModal.close(this.name)
    },
  },
  watch: {
    watchData: {
      handler(to, from) {
        if (this.isCurrent && to && from) {
          this.refreshKey++
        }
      },
    },
  },
}

function isVisible(elem) {
  return elem.checkVisibility({
    checkOpacity: true,
    checkVisibilityCSS: true,
    visibilityProperty: true,
    contentVisibilityAuto: true,
    opacityProperty: true,
  })
  // &&
  // (elem.offsetWidth > 0 ||
  //   elem.offsetHeight > 0 ||
  //   elem.getClientRects().length > 0)
}
</script>
