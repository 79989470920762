let scrollTimer = null

function isInViewport(element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

function isVisible(elem) {
  return (
    // elem.checkVisibility({
    //   checkOpacity: true,
    //   checkVisibilityCSS: true,
    //   visibilityProperty: true,
    //   contentVisibilityAuto: true,
    //   opacityProperty: true,
    // }) &&
    elem.offsetWidth > 0 ||
    elem.offsetHeight > 0 ||
    elem.getClientRects().length > 0
  )
}

export default async function (
  selector,
  force = false,
  wait = 500,
  animate = true,
  block = 'center'
) {
  clearTimeout(scrollTimer)
  return new Promise((resolve) => {
    scrollTimer = setTimeout(() => {
      const arr = Array.from(document.querySelectorAll(selector))
      const el = arr.find((item) => isVisible(item))
      if (el && (force || !isInViewport(el))) {
        el.scrollIntoView({
          block: block,
          behavior: animate ? 'smooth' : 'instant',
          inline: 'nearest',
        })
        resolve(el)
      } else {
        resolve(el)
      }
    }, wait)
  })
}
