import Vue from 'vue'
import Vuex from 'vuex'

import windowSizesStore from './modules/windowSizesStore'
import locations from './modules/locations'
import user from './modules/user'
import mail from './modules/mail'
import events from './modules/calendar/events'
import calendars from './modules/calendar/calendars'
import customers from './modules/customers'
import vendors from './modules/vendors'
import paymentAccounts from './modules/paymentAccounts'
import commodity from './modules/commodity'
import commodities from './modules/commodities'
import estimates from './modules/estimates'
import invoices from './modules/invoices'
import bookings from './modules/bookings'
import rates from './modules/rates'
import employees from './modules/employees'
import shippingFile from './modules/shippingFile'
import shippingFileLoading from './modules/shippingFile/shippingFileLoading'
import shippingFilesList from './modules/shippingFilesList/shippingFilesList'
import expenses from './modules/expenses'
import paymentsReceived from './modules/paymentsReceived'
import paymentReceivedEdit from './modules/paymentsReceived/paymentReceivedEdit'
import deposits from './modules/deposits'
import projects from './modules/projects'
import companies from './modules/companies'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    windowSizesStore,
    locations,
    user,
    mail,
    events,
    calendars,
    customers,
    vendors,
    commodity,
    commodities,
    estimates,
    invoices,
    bookings,
    rates,
    employees,
    shippingFile,
    shippingFileLoading,
    shippingFilesList,
    expenses,
    paymentsReceived,
    paymentReceivedEdit,
    deposits,
    companies,
    paymentAccounts,
    projects,
  },
  state: {
    isSideNavOpen: true,
    hasUnsavedChanges: false,
  },
  getters: {
    hasUnsavedChanges(state) {
      return state.hasUnsavedChanges
    },
  },
  mutations: {
    setUnsaved(state, to) {
      state.hasUnsavedChanges = to
    },
    showSideNav(state) {
      state.isSideNavOpen = true
      localStorage.setItem('theNavIsOpen', 'true')
    },
    hideSideNav(state) {
      state.isSideNavOpen = false
      localStorage.setItem('theNavIsOpen', 'false')
    },
  },
  actions: {
    init({ dispatch }) {
      dispatch('windowSizesStore/init')
      dispatch('user/init')
    },
    toggleSideNav({ commit, state }, value) {
      if (typeof value === 'boolean') {
        if (value) commit('showSideNav')
        else commit('hideSideNav')
      } else {
        if (state.isSideNavOpen) commit('hideSideNav')
        else commit('showSideNav')
      }
    },
    checkSideNav({ commit }) {
      let navState = localStorage.getItem('theNavIsOpen')

      if (navState === 'false') {
        commit('hideSideNav')
      } else {
        commit('showSideNav')
      }
    },
  },

  strict: process.env.NODE_ENV !== 'production',
})

store.dispatch('init').catch(() => {})
export default store
