import { getDate } from '@/helpers/formatDate'
import { TIME_FORMAT, TIME_FORMAT_FULL } from '@/constants/date'

export default function (
  date,
  withTime = false,
  emptyValue = undefined,
  full = false
) {
  let dateMoment = getDate(date, withTime)
  if (!dateMoment) return emptyValue

  // dateMoment.local()
  return dateMoment.format(full ? TIME_FORMAT_FULL : TIME_FORMAT)
}
