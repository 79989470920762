import { axiosApi, setToken } from '@/axios'
import {
  adminConfigs,
  customerConfigs,
  employeeConfigs,
  vendorConfigs,
} from '@/constants/personsConfigs'
import moment, { updateZone } from '@/helpers/useMoment'
import $router from '@/router'
import { updateAbility } from '@/services/ability'
import Vue from 'vue'

let logTimer = null

export default {
  namespaced: true,
  state: {
    pending: false,
    token: null,
    profile: null,
    company_profile: null,
    current_log: null,
    pendingCurrentLog: false,
    currentLogFromStartDuration: 0,
    currentRunningDeductionDuration: 0,
    currentRunningPinDuration: [0, 0],
    type: null,
    loaded: false,
    userPromise: null,
    pendingFiles: false,
    logoutPromise: null,
    validate_rules: {},
  },
  getters: {
    isLogged(state) {
      return state.loaded
    },
    isLoggedAsCustomer(state) {
      return String(state.type) === 'customer'
    },
    isLoggedAsAdmin(state) {
      return String(state.type) === 'super_admin'
    },
    isLoggedAsEmployee(state) {
      return String(state.type) === 'employee'
    },
    userName(state) {
      return state.profile
        ? `${state?.profile?.first_name ?? ''} ${
            state?.profile?.last_name ?? ''
          }`.trim()
        : ''
    },
    userProfile(state) {
      return state?.profile ?? null
    },
    userId(state) {
      return state.profile ? String(state.profile.id) : null
    },
    userConfigs(state) {
      switch (state.type) {
        case 'super_admin': {
          return adminConfigs
        }
        case 'customer': {
          return customerConfigs
        }
        case 'vendor': {
          return vendorConfigs
        }
        case 'employee': {
          return employeeConfigs
        }
        default: {
          return {}
        }
      }
    },
    isShowMainMenu(state) {
      //hide main menu for types:
      return !['customer', 'vendor'].includes(state?.type ?? '')
    },
    maxFileSize(state) {
      return state?.validate_rules?.upload_max_filesize ?? 52428800
    },
    currentLog(state) {
      return state?.current_log ?? null
    },
    isCurrentLogRunning(state, getters) {
      const currentLog = getters.currentLog
      const lastPin = currentLog?.pins?.at(-1)
      const start = currentLog?.inputs?.time_start?.value ?? null
      const end = currentLog?.inputs?.time_end?.value ?? null
      const lastPinEnd = lastPin?.time_end?.value ?? null

      return start && !end && !lastPinEnd
    },
    isCurrentLogDeductionRunning(state, getters) {
      const currentLog = getters.currentLog
      const lastDeduction = currentLog?.deductions?.at(-1)
      const lastDeductionStart = lastDeduction?.time_start?.value ?? null
      const lastDeductionEnd = lastDeduction?.time_end?.value ?? null
      return lastDeductionStart && !lastDeductionEnd
    },
    isCurrentLogPinRunning(state, getters) {
      const currentLog = getters.currentLog
      const lastPin = currentLog?.pins?.at(-1)
      const lastPinStart = lastPin?.time_start?.value ?? null
      const lastPinEnd = lastPin?.time_end?.value ?? null
      return lastPinStart && !lastPinEnd
    },
    currentLogStart(state, getters) {
      const currentLog = getters.currentLog
      return currentLog?.inputs?.time_start?.value ?? null
    },
    currentRunningDeduction(state, getters) {
      const currentLog = getters.currentLog
      const lastDeduction = currentLog?.deductions?.at(-1)
      const lastDeductionStart = lastDeduction?.time_start?.value ?? null
      const lastDeductionEnd = lastDeduction?.time_end?.value ?? null
      if (lastDeductionStart && !lastDeductionEnd) {
        return lastDeduction
      } else {
        return null
      }
    },
    currentRunningPin(state, getters) {
      const currentLog = getters.currentLog
      const lastPin = currentLog?.pins?.at(-1)
      const lastPinStart = lastPin?.time_start?.value ?? null
      const lastPinEnd = lastPin?.time_end?.value ?? null
      if (lastPinStart && !lastPinEnd) {
        return lastPin
      } else {
        return null
      }
    },
    currentRunningDeductionDuration(state) {
      return state.currentRunningDeductionDuration
    },
    currentRunningPinDuration(state) {
      return state.currentRunningPinDuration
    },
    currentLogFromStartDuration(state) {
      return state.currentLogFromStartDuration
    },
    currentFinishedDeductions(state, getters) {
      const currentLog = getters.currentLog
      return (
        currentLog?.deductions?.map((deduction) => {
          const start = deduction.time_start?.value ?? undefined
          const end = deduction.time_end?.value ?? undefined
          if (start && end) {
            return (
              moment
                .duration(moment(end).diff(moment(start), 'seconds'), 'seconds')
                .asSeconds() * 1000
            )
          } else {
            return 0
          }
        }) ?? []
      )
    },
    currentFinishedPins(state, getters) {
      const currentLog = getters.currentLog
      return (
        currentLog.pins?.map((pin) => {
          const start = pin.time_start?.value ?? undefined
          const end = pin.time_end?.value ?? undefined

          if (start && end) {
            const duration =
              moment
                .duration(moment(end).diff(moment(start), 'seconds'), 'seconds')
                .asSeconds() * 1000
            const pinRange = moment.range(moment(start), moment(end))

            const intersectDurations =
              currentLog?.deductions?.reduce((acc, currentItem) => {
                const itemStart = currentItem.time_start?.value ?? undefined
                if (!itemStart) return acc
                const itemEnd = currentItem.time_end?.value ?? undefined
                const itemRange = moment.range(
                  moment(itemStart),
                  moment(itemEnd)
                )

                if (pinRange.overlaps(itemRange, { adjacent: true })) {
                  const intersect = pinRange.intersect(itemRange)
                  acc += intersect?.diff('seconds') * 1000 ?? 0
                }
                return acc
              }, 0) ?? 0

            return [duration, intersectDurations]
          } else {
            return [0, 0]
          }
        }) ?? []
      )
    },
    totalPinsDurationMS(state, getters) {
      return getters.currentFinishedPins.reduce((res, currentValue) => {
        return res + (currentValue[0] - currentValue[1])
      }, 0)
    },
    totalFinishedDeductionsDurationMS(state, getters) {
      return getters.currentFinishedDeductions.reduce((res, currentValue) => {
        return res + currentValue
      }, 0)
    },
    currentCompanyTimeZone(state) {
      return state?.company_profile?.working_time_zone
    },
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    addFiles(state, files) {
      state.profile.files = [].concat(files, state.profile.files)
    },
    deleteFile(state, index) {
      state.profile.files.splice(index, 1)
    },
    changeFile(state, { index, file }) {
      Vue.set(state.profile.files, index, file)
    },
    updateCurrentLogTimer(state, { getters }) {
      const start = getters['currentLogStart']
      const currentRunningDeductionStart =
        getters['currentRunningDeduction']?.time_start?.value
      const currentRunningPinStart =
        getters['currentRunningPin']?.time_start?.value

      if (start) {
        state.currentLogFromStartDuration =
          moment
            .duration(moment().diff(moment(start), 'seconds'), 'seconds')
            .asSeconds() * 1000
      } else {
        state.currentLogFromStartDuration = 0
      }

      if (
        getters['isCurrentLogDeductionRunning'] &&
        currentRunningDeductionStart
      ) {
        state.currentRunningDeductionDuration =
          moment
            .duration(
              moment().diff(moment(currentRunningDeductionStart), 'seconds'),
              'seconds'
            )
            .asSeconds() * 1000
      } else {
        state.currentRunningDeductionDuration = 0
      }

      if (getters['isCurrentLogPinRunning'] && currentRunningPinStart) {
        const duration =
          moment
            .duration(
              moment().diff(moment(currentRunningPinStart), 'seconds'),
              'seconds'
            )
            .asSeconds() * 1000

        const pinRange = moment.range(moment(currentRunningPinStart), moment())
        const intersectDurations =
          getters['currentLog']?.deductions?.reduce((acc, currentItem) => {
            const itemStart = currentItem.time_start?.value ?? undefined
            if (!itemStart) return acc
            const itemEnd = currentItem.time_end?.value ?? undefined
            const itemRange = moment.range(moment(itemStart), moment(itemEnd))

            if (pinRange.overlaps(itemRange, { adjacent: true })) {
              const intersect = pinRange.intersect(itemRange)
              acc += intersect?.diff('seconds') * 1000 ?? 0
            }
            return acc
          }, 0) ?? 0

        state.currentRunningPinDuration = [duration, intersectDurations]
      } else {
        state.currentRunningPinDuration = [0, 0]
      }
    },
  },
  actions: {
    init({ commit, dispatch }) {
      if (window.location.href.includes('new-password')) {
        return dispatch('reset')
      }

      const token = getTokenFromLocalstorage()
      if (!token) return

      commit('setProperty', ['token', token])
      dispatch('setTokenToAllConfigs')
      dispatch('loadUser').catch(() => {})
    },
    setTokenToAllConfigs({ state }) {
      const token = state.token
      setToken(token)
      if (token) {
        setTokenToLocalstorage(token)
      } else {
        removeTokenFromLocalStorage()
      }
    },
    setUserData({ commit, getters }, data) {
      commit('setProperty', [
        'profile',
        {
          ...data.item.attributes,
        },
      ])
      commit('setProperty', ['company_profile', data.company_profile])
      updateZone(getters['currentCompanyTimeZone'])
      commit('setProperty', [
        'current_log',
        data?.current_log?.attributes ?? null,
      ])
      commit('setProperty', ['validate_rules', data.validate_rules || {}])
      commit('setProperty', ['type', data.item.type])
      commit('setProperty', ['loaded', true])
    },
    loadUser({ state, commit, dispatch }, clear = false) {
      if (clear) {
        commit('setProperty', ['loaded', false])
        commit('setProperty', ['promiseUser', null])
        commit('setProperty', ['pending', false])
      } else {
        if (state.loaded) {
          commit('setProperty', ['pending', false])
          if (state.userPromise) {
            commit('setProperty', ['userPromise', null])
          }
          return Promise.resolve(state)
        }
        if (state.pending && state.userPromise) {
          return state.userPromise
        }
      }

      commit('setProperty', ['pending', true])

      const promise = axiosApi
        .get('/office')
        .then(({ data }) => {
          dispatch('setUserData', data)

          return state
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
          commit('setProperty', ['userPromise', null])
          dispatch('onUserLoad')
        })

      commit('setProperty', ['userPromise', promise])
      return promise
    },
    async passwordReset({ state, commit, dispatch }, sendData) {
      if (state.loaded) {
        await dispatch('logout').catch(() => {})
      }
      commit('setProperty', ['pending', true])
      return axiosApi
        .post('/reset-password', sendData)
        .then(({ data }) => {
          dispatch('afterAuth', data)
          return data
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
          dispatch('onUserLoad')
        })
    },
    async login({ state, commit, dispatch }, sendData) {
      if (state.loaded) {
        await dispatch('logout').catch(() => {})
      }
      commit('setProperty', ['pending', true])
      return axiosApi
        .post('/login', sendData)
        .then(({ data }) => {
          if (data.status_code !== 'need_confirm_code') {
            dispatch('afterAuth', data)
          }
          return data
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
          dispatch('onUserLoad')
        })
    },
    afterAuth({ commit, dispatch }, data) {
      const token = `${data.auth.token_type} ${data.auth.access_token}`
      commit('setProperty', ['token', token])
      dispatch('setTokenToAllConfigs')

      dispatch('setUserData', data)
    },
    onUserLoad({ state }) {
      if (state.type) {
        updateAbility(state)
        console.info(`loaded user, type - ${state.type}`)
      }
    },
    onLogout({ dispatch }, { saveCurrentPath = false } = {}) {
      const from = $router.currentRoute.fullPath
      return $router
        .push({
          name: 'auth',
          query: {
            redirect:
              saveCurrentPath && from && from !== '/' ? from : undefined,
          },
        })
        .catch(() => {})
        .finally(() => {
          dispatch('reset')
        })
    },
    reset({ dispatch, commit }) {
      commit('setProperty', ['token', null])
      commit('setProperty', ['profile', null])
      commit('setProperty', ['company_profile', null])
      commit('setProperty', ['current_log', null])
      commit('setProperty', ['type', null])
      commit('setProperty', ['loaded', false])
      commit('setProperty', ['promiseUser', null])
      commit('setProperty', ['logoutPromise', null])
      commit('setProperty', ['pending', false])
      dispatch('setTokenToAllConfigs')
      updateAbility()
      // updateZone()
    },
    logout({ commit, dispatch, state }) {
      commit('setProperty', ['pending', true])

      if (['auth', 'auth-new-password'].includes($router.currentRoute.name)) {
        return dispatch('reset')
      }

      if (state.logoutPromise) {
        return state.logoutPromise
      }

      const logoutPromise = axiosApi.get('/logout').finally(() => {
        return dispatch('onLogout')
      })
      commit('setProperty', ['logoutPromise', logoutPromise])
      return logoutPromise
    },
    uploadFiles({ state, getters, commit }, { files }) {
      if (!state.profile.id) return
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i])
      }

      commit('setProperty', ['pendingFiles', true])

      axiosApi
        .post(
          `${getters.userConfigs.httpBaseLink}/${state.profile.id}/files/`,
          formData
        )
        .then(({ data }) => {
          commit(
            'addFiles',
            data.map((item) => item.attributes)
          )
        })
        .finally(() => {
          commit('setProperty', ['pendingFiles', false])
        })
    },
    changeName({ state, getters, commit }, { index, name }) {
      const file = state.profile.files[index]

      const sendData = {
        name,
      }

      commit('setProperty', ['pendingFiles', true])
      axiosApi
        .put(
          `${getters.userConfigs.httpBaseLink}/${state.profile.id}/files/${file.id}/change_name`,
          sendData
        )
        .then(({ data }) => {
          commit('changeFile', { index, file: data.item.attributes })
        })
        .finally(() => {
          commit('setProperty', ['pendingFiles', false])
        })
    },
    deleteFile({ state, getters, commit }, { index }) {
      const file = state.profile.files[index]
      commit('setProperty', ['pendingFiles', true])
      axiosApi
        .delete(
          `${getters.userConfigs.httpBaseLink}/${state.profile.id}/files/${file.id}`
        )
        .then(() => {
          commit('deleteFile', index)
        })
        .finally(() => {
          commit('setProperty', ['pendingFiles', false])
        })
    },
    startNewLog({ commit }, { sendData, isChange = false }) {
      commit('setProperty', ['pendingCurrentLog', true])
      return axiosApi
        .post(
          isChange ? '/time-log/change-pin' : '/time-log/start-log',
          sendData
        )
        .then(({ data }) => {
          const current_log = data?.data?.attributes ?? null
          commit('setProperty', ['current_log', current_log])
          return current_log
        })
        .finally(() => {
          commit('setProperty', ['pendingCurrentLog', false])
        })
    },
    startDeduction({ commit, getters }, { sendData }) {
      commit('setProperty', ['pendingCurrentLog', true])
      return axiosApi
        .post(`/time-log/start-deduction`, sendData)
        .then(({ data }) => {
          const current_log = data?.data?.attributes ?? null
          commit('setProperty', ['current_log', current_log])
          commit('updateCurrentLogTimer', { getters })
          return current_log
        })
        .finally(() => {
          commit('setProperty', ['pendingCurrentLog', false])
        })
    },
    loadCurrentLog({ commit, getters }) {
      commit('setProperty', ['pendingCurrentLog', true])

      return axiosApi
        .get('/time-log/current-log')
        .then(({ data }) => {
          const current_log = data?.data?.attributes ?? null
          commit('setProperty', ['current_log', current_log])
          commit('updateCurrentLogTimer', { getters })
          return current_log
        })
        .finally(() => {
          commit('setProperty', ['pendingCurrentLog', false])
        })
    },
    runLogTimer({ commit, getters }) {
      clearInterval(logTimer)
      commit('updateCurrentLogTimer', { getters })
      logTimer = setInterval(() => {
        commit('updateCurrentLogTimer', { getters })
      }, 1000)
    },
    stopLogTimer({ commit, getters }) {
      clearInterval(logTimer)
      commit('updateCurrentLogTimer', { getters })
    },
    stopLogTimerRequest({ commit, dispatch }) {
      commit('setProperty', ['pendingCurrentLog', true])
      return axiosApi
        .post(`/time-log/end-log`)
        .then(({ data }) => {
          const saved_log = data?.data?.attributes ?? null

          // const current_log = JSON.parse(JSON.stringify(getters['currentLog']))
          // current_log.inputs.time_end = saved_log.inputs.time_end
          // if (
          //   current_log.pins?.at(-1)?.time_end &&
          //   !current_log.pins?.at(-1)?.time_end?.value
          // ) {
          //   current_log.pins.at(-1).time_end = saved_log.inputs.time_end
          // }
          //
          // if (
          //   current_log.deductions?.at(-1)?.time_end &&
          //   !current_log.deductions?.at(-1)?.time_end?.value
          // ) {
          //   current_log.deductions.at(-1).time_end = saved_log.inputs.time_end
          // }

          commit('setProperty', ['current_log', saved_log])

          dispatch('stopLogTimer')
        })
        .finally(() => {
          commit('setProperty', ['pendingCurrentLog', false])
        })
    },
    stopDeductionRequest({ commit }) {
      commit('setProperty', ['pendingCurrentLog', true])
      return axiosApi
        .post(`/time-log/end-deduction`)
        .then(({ data }) => {
          const current_log = data?.data?.attributes ?? null
          commit('setProperty', ['current_log', current_log])
          // dispatch('loadCurrentLog')
        })
        .finally(() => {
          commit('setProperty', ['pendingCurrentLog', false])
        })
    },
  },
}

function setTokenToLocalstorage(token) {
  window.localStorage.setItem('token', token)
}

function getTokenFromLocalstorage() {
  return window.localStorage.getItem('token') || null
}

function removeTokenFromLocalStorage() {
  window.localStorage.removeItem('token')
}
