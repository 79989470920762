<template>
  <div id="app">
    <transition name="fade" appear mode="out-in" @enter="onEnter">
      <router-view />
    </transition>
    <TrackerPanel />
  </div>
</template>

<script>
import TrackerPanel from '@/components/TrackerPanel.vue'
import { updateZone } from '@/helpers/useMoment'
import { isEqual } from 'lodash'

export default {
  components: { TrackerPanel },
  data() {
    return {
      generalPreloader: null,
    }
  },
  methods: {
    removeGeneralPreloader() {
      this.generalPreloader = document.getElementById('general-preloader')
      if (!this.generalPreloader) return
      this.generalPreloader.addEventListener('transitionend', this.onEnd, false)
      this.generalPreloader.classList.add('_hide')
    },
    onEnd(e) {
      if (e.propertyName !== 'opacity') return
      this.generalPreloader.removeEventListener(
        'transitionend',
        this.onEnd,
        false
      )
      this.generalPreloader.remove()
      this.generalPreloader = null
    },
    onEnter() {
      this.removeGeneralPreloader()
    },
  },
  created() {
    this.unwatchCurrentLogStart = this.$store.watch(
      (state, getters) => [
        getters['user/currentLogStart'],
        getters['user/isCurrentLogRunning'],
      ],
      (newValue, oldValue) => {
        if (isEqual(newValue, oldValue)) return
        if (newValue[0] && newValue[1]) {
          this.$store.dispatch('user/runLogTimer')
        } else {
          this.$store.dispatch('user/stopLogTimer')
        }
      },
      {
        immediate: true,
      }
    )
    this.unwatchCompanyTimeZone = this.$store.watch(
      (state, getters) => getters['user/currentCompanyTimeZone'],
      (newValue) => {
        updateZone(newValue)
      }
    )
  },
  beforeDestroy() {
    this.unwatchCurrentLogStart()
    this.unwatchCompanyTimeZone()
  },
}
</script>
