<template>
  <transition name="fade">
    <div
      v-if="$can($ableManage, $abilitySubjects.TIME_KIOSK)"
      class="tracker-panel"
      :class="{ _open: !isClosed }"
    >
      <button
        type="button"
        class="tracker-panel__toggle btn _square"
        :class="{ _grey: !currentLogStart || !isCurrentLogRunning }"
        @click="togglePanel"
      >
        <BaseSvg name="ico-arrow" class="tracker-panel__toggle-arrow" />
        <BaseSvg name="ico-time" />
      </button>
      <div class="tracker-panel__inner">
        <BasePreloader v-if="pendingCurrentLog" />
        <div class="tracker-panel__from-start">
          <span>
            {{
              (isCurrentLogRunning ? currentLogFromStartDuration : 0)
                | timerCounterFormatByMS
            }}
          </span>

          <span>{{
            (isCurrentLogRunning ? currentLogStart : 0) | timeFormat
          }}</span>
        </div>
        <router-link :to="{ name: 'time-kiosk' }" class="tracker-panel__timers">
          <BaseSvg name="ico-link" />
          <div
            class="tracker-panel__timer _total"
            :class="{ _active: !isCurrentLogDeductionRunning }"
          >
            {{
              (isCurrentLogRunning
                ? currentLogFromStartDuration -
                  totalFinishedDeductionsDurationMS -
                  currentRunningDeductionDuration
                : 0) | timerCounterFormatByMS
            }}
          </div>
          <div
            class="tracker-panel__timer _deduction"
            :class="{ _active: isCurrentLogDeductionRunning }"
          >
            {{
              (isCurrentLogRunning
                ? totalFinishedDeductionsDurationMS +
                  currentRunningDeductionDuration
                : 0) | timerCounterFormatByMS
            }}
          </div>
        </router-link>

        <div class="tracker-panel__actions">
          <BaseTooltip v-if="!isCurrentLogRunning" autoWidth class="_top">
            <button
              @click.prevent="startTimer"
              class="tracker-panel__btn btn _square"
            >
              <BaseSvg name="ico-start-play" />
            </button>
            <template v-slot:dropdown>Clock in</template>
          </BaseTooltip>

          <template v-else-if="isCurrentLogRunning">
            <BaseTooltip v-if="isCurrentLogDeductionRunning" autoWidth>
              <button
                @click.prevent="endDeduction"
                class="tracker-panel__btn btn _square _yellow"
              >
                <BaseSvg name="ico-cup-off" />
              </button>
              <template v-slot:dropdown>End deduction</template>
            </BaseTooltip>

            <BaseTooltip v-else autoWidth>
              <button
                @click.prevent="addDeduction"
                class="tracker-panel__btn btn _square _yellow"
              >
                <BaseSvg name="ico-cup" />
              </button>
              <template v-slot:dropdown>Start deduction</template>
            </BaseTooltip>

            <BaseTooltip autoWidth>
              <button
                @click.prevent="changePin"
                class="tracker-panel__btn btn _square _blue"
              >
                <BaseSvg name="ico-sort" />
              </button>
              <template v-slot:dropdown>Change pin</template>
            </BaseTooltip>

            <BaseTooltip autoWidth>
              <button
                @click.prevent="stopTimer"
                class="tracker-panel__btn btn _square _red"
              >
                <BaseSvg name="ico-stop-play" />
              </button>
              <template v-slot:dropdown>Stop</template>
            </BaseTooltip>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TrackerPanel',
  data() {
    return {
      isClosed: false,
    }
  },
  computed: {
    ...mapGetters('user', [
      'isLoggedAsEmployee',
      'currentLog',
      'currentLogStart',
      'currentLogFromStartDuration',
      'totalFinishedDeductionsDurationMS',
      'isCurrentLogRunning',
      'isCurrentLogDeductionRunning',
      'currentRunningDeductionDuration',
    ]),
    ...mapState('user', ['pendingCurrentLog']),
  },
  methods: {
    startTimer() {
      this.$vModal.open('time-tracker', {
        variant: 'start',
      })
    },
    addDeduction() {
      this.$vModal.open('time-tracker', {
        variant: 'startDeduction',
      })
    },
    endDeduction() {
      this.$store.dispatch('user/stopDeductionRequest')
    },
    changePin() {
      this.$vModal.open('time-tracker', {
        variant: 'changePin',
      })
    },
    stopTimer() {
      this.$store.dispatch('user/stopLogTimerRequest')
    },
    togglePanel() {
      if (this.isClosed) {
        this.isClosed = false
        window.localStorage.setItem('isTrackerPanelClosed', false)
      } else {
        this.isClosed = true
        window.localStorage.setItem('isTrackerPanelClosed', true)
      }
    },
  },
  created() {
    this.isClosed = JSON.parse(
      window.localStorage.getItem('isTrackerPanelClosed') ?? false
    )
  },
}
</script>

<style lang="less" scoped>
.tracker-panel {
  position: fixed;
  bottom: 5rem;
  right: 0;
  z-index: 1000;
  width: 15rem;
  font-variant-numeric: tabular-nums;
  transition: transform @time;
  transform: translateX(100%);

  &__inner {
    width: 100%;
    border-radius: 1rem 0 0 1rem;
    background: @c-grey-lightest;
    box-shadow: 0 0.1rem 0.6rem fade(#000000, 15);
    padding: 1.4rem;
    position: relative;
    z-index: 2;
  }

  &._open {
    transform: translateY(0);
  }

  &._open &__toggle {
    opacity: 1;
  }

  &._open &__toggle-arrow {
    transform: rotate(0);
  }

  &__toggle {
    position: absolute;
    right: 100%;
    top: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    opacity: 0.6;
    box-shadow: 0 0.1rem 0.6rem fade(#000000, 15);
    z-index: 1;

    .hoverOnly({
      &:hover {
        opacity: 1;
      }
    });

    &-arrow {
      width: 1rem;
      height: 1rem;
      margin-right: -0.5rem !important;
      margin-left: -0.3rem !important;
      transform: rotate(180deg);
    }
  }

  &__from-start {
    font-size: 1.2rem;
    color: @c-grey;
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    grid-gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
  }

  &__timers {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    text-decoration: none;
    position: relative;

    svg {
      position: absolute;
      top: -0.7rem;
      right: -0.2rem;
      width: 1.6rem;
      height: 1.6rem;
      opacity: 0.6;
      transition: opacity @time;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &__timer {
    font-size: 2.4rem;
    font-weight: 500;
    transition-property: transform;
    transition-duration: @time;

    &._total {
      color: @c-green;
    }

    &._deduction {
      color: @c-yellow;
    }

    &._active {
      &._deduction {
        transform: translateY(-2rem);
      }
    }

    &:not(._active) {
      transform: scale(0.62);

      &._total {
        transform: scale(0.62) translateY(3.3rem);
      }
    }
  }
}
</style>
