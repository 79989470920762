import Vue from 'vue'
import {
  PER_PAGE_LIMIT,
  QUERY_PAGE_NAME,
  QUERY_SEARCH_GLOBAL_NAME,
  QUERY_SEARCH_NAME,
} from '@/constants/shippingFiles'
import { axiosApi } from '@/axios'
import router from '@/router'
import { CancelToken } from 'axios'
import pdf from './pdf'
import print from './print'
import email from './email'

let cancelRequest = null

function getState() {
  return {
    pending: false,
    items: [],
    renderStatus: null,
    tabsTotals: {},
    total: 0,
  }
}

export default {
  namespaced: true,
  modules: {
    pdf,
    print,
    email,
  },
  state: getState(),
  getters: {
    page: () => (currentRoute) => {
      return +currentRoute.query[QUERY_PAGE_NAME] || 1
    },
    from: (state, getters) => (currentRoute) => {
      return state.items.length
        ? (getters['page'](currentRoute) - 1) * PER_PAGE_LIMIT + 1
        : 0
    },
    to: (state, getters) => (currentRoute) => {
      let t = getters['page'](currentRoute) * PER_PAGE_LIMIT
      return t < state.total ? t : state.total
    },
    selectedAmount(state) {
      return state.items.reduce((result, item) => {
        return item.selected ? result + 1 : result
      }, 0)
    },
    isSomeSelected(state, getters) {
      return !!(state.items.length && getters.selectedAmount)
    },
    isAllSelected(state, getters) {
      return getters.selectedAmount === state.items.length
    },
    renderStatus(state) {
      return state.renderStatus
    },
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      Vue.set(state, key, value)
    },
    onSelectChange(state, [index, value]) {
      Vue.set(state.items[index], 'selected', value)
    },
    onAllSelectionChange(state, value) {
      state.items.forEach((item) => {
        item.selected = value
      })
    },
    toggleItem(state, index) {
      Vue.set(state.items[index], 'isOpen', !state.items[index].isOpen)
    },
  },
  actions: {
    reset({ commit }) {
      if (cancelRequest) {
        cancelRequest.cancel()
        cancelRequest = null
      }
      commit('setProperty', ['pending', false])
      commit('setProperty', ['items', []])
      commit('setProperty', ['renderStatus', null])
      commit('setProperty', ['tabsTotals', {}])
      commit('setProperty', ['total', 0])
    },
    load({ getters, commit }) {
      if (cancelRequest) {
        cancelRequest.cancel()
        cancelRequest = null
      }

      const currentRoute = router.currentRoute

      commit('setProperty', ['pending', true])
      const search = currentRoute.query[QUERY_SEARCH_NAME]
      const search_global = currentRoute.query[QUERY_SEARCH_GLOBAL_NAME]

      cancelRequest = CancelToken.source()

      return axiosApi
        .get('/shipping_files', {
          cancelToken: cancelRequest.token,
          params: {
            limit: PER_PAGE_LIMIT,
            page: getters['page'](currentRoute),
            status: currentRoute.params.status,
            query_global: search_global,
            query: search,
          },
        })
        .then(({ data }) => {
          commit('setProperty', ['renderStatus', currentRoute.params.status])
          const items = data.data.map((item) => {
            item = item.attributes
            item.selected = false
            item.commodities = getCommoditiesList(item)
            item.containerItems = getContainerItemsList(item)

            return item
          })

          commit('setProperty', ['items', items])
          commit('setProperty', ['total', data.meta.total])
          commit('setProperty', [
            'tabsTotals',
            data.additional_information.tabs_totals,
          ])
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
    deleteOne({ dispatch }, id) {
      return dispatch('multipleDelete', [id])
    },
    deleteSelected({ state, dispatch }) {
      const ids = state.items.reduce((ids, item) => {
        if (item.selected) ids.push(item.id)
        return ids
      }, [])

      dispatch('multipleDelete', ids)
    },
    multipleDelete({ dispatch }, ids) {
      if (!ids || !ids.length) return Promise.reject()

      return new Promise((resolve, reject) => {
        Vue.prototype.$vAccept.open({
          title:
            ids.length > 1 ? 'Delete shipping files?' : 'Delete shipping file?',
          onAccept: () => {
            axiosApi
              .delete(`shipping_files/mass_delete`, {
                params: {
                  id: ids,
                },
              })
              .then(() => {
                dispatch('load')
                resolve()
              })
              .catch((e) => {
                // Vue.prototype.$errorCatchDefault(e)
                reject(e)
              })
          },
        })
      })
    },
  },
}

function getCommoditiesList(item) {
  return item.booking.trucking_information.reduce((result, trucking) => {
    const r = trucking.containers.reduce((contResult, container) => {
      container.items.forEach((item) => {
        contResult = contResult.concat(item.commodities)
      })

      return contResult
    }, [])

    return result.concat(r)
  }, [])
}

function getContainerItemsList(item) {
  return item.booking.trucking_information.reduce((result, trucking) => {
    const r = trucking.containers.reduce((contResult, container) => {
      return contResult.concat(container.items)
    }, [])

    return result.concat(r)
  }, [])
}
