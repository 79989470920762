import RootContainer from './RootContainer'

export default {
  install(Vue) {
    const root = new Vue({
      name: 'VAcceptPlugin',
      data: {
        isOpen: false,
        pending: false,
        withPassword: false,
        onAcceptCallBack: null,
        onCancelCallBack: null,
        modalProps: {
          title: null,
          text: null,
          error: null,
          acceptBtnText: null,
          cancelBtnText: null,
        },
      },
      render: (createElement) => createElement(RootContainer),
    })

    root.$mount(document.body.appendChild(document.createElement('div')))

    Vue.prototype.$vAccept = {
      root,
      /**
       * @param {{onAccept: onAccept}} args
       * @param {boolean} args.withPassword
       * @param {boolean} args.noCloseOnAccept
       * @param {function} args.onAccept
       * @param {function} [args.onCancel]
       * @param {string} [args.title]
       * @param {string} [args.text]
       * @param {string} [args.error]
       * @param {string} [args.acceptBtnText]
       * @param {string} [args.cancelBtnText]
       * */
      open(args) {
        root.pending = false
        if (typeof args !== 'object' || typeof args.onAccept !== 'function') {
          return console.warn(
            '$vAccept aspect object with property callback "onAccept"'
          )
        }

        root.withPassword = args.withPassword || false
        root.noCloseOnAccept = args.noCloseOnAccept || false
        root.onAcceptCallBack = args.onAccept || null
        root.onCancelCallBack = args.onCancel || null

        root.modalProps.title = args.title || null
        root.modalProps.text = args.text || null
        root.modalProps.error = args.error || null
        root.modalProps.acceptBtnText = args.acceptBtnText || 'Accept'
        root.modalProps.cancelBtnText = args.cancelBtnText || 'Cancel'

        root.isOpen = true
        blockScroll()

        document.documentElement.addEventListener('keyup', closeByESC)
      },
      close() {
        root.pending = false
        this._close()
      },
      /**
       * @param {Object} args
       * @param {string} [args.title]
       * @param {string} [args.text]
       * @param {string} [args.error]
       * */
      pushProps(args) {
        root.pending = false

        for (let key in args) {
          if (Object.prototype.hasOwnProperty.call(root.modalProps, key)) {
            root.modalProps[key] = args[key]
          }
        }
      },
      _accept(password) {
        root.modalProps.error = null

        if (root.onAcceptCallBack) root.onAcceptCallBack(password)
        if (root.noCloseOnAccept) {
          root.pending = true
          return
        }
        this._close()
      },
      _cancel() {
        if (root.onCancelCallBack) root.onCancelCallBack()
        this._close()
      },
      _close() {
        root.isOpen = false
        document.documentElement.removeEventListener('keyup', closeByESC)
        unBlockScroll()
      },
    }

    function closeByESC(e) {
      if (e.key === 'Escape') {
        Vue.prototype.$vAccept._cancel()
      }
    }
  },
}

function blockScroll() {
  const scrollWidth = getScrollWidth()
  document.documentElement.style.overflowY = 'hidden'
  document.documentElement.style.paddingRight = scrollWidth + 'px'
}

function unBlockScroll() {
  document.documentElement.style.overflowY = null
  document.documentElement.style.paddingRight = null
}

function getScrollWidth() {
  return (
    window.innerWidth - document.documentElement.clientWidth ||
    parseFloat(getComputedStyle(document.documentElement).paddingRight) ||
    0
  )
}
