import { updateTimeZone } from '@/axios'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)
moment.locale('en')

// moment.tz.setDefault('America/Los_Angeles')
// moment.tz.setDefault('Etc/UTC')
moment.tz.setDefault(moment.tz.guess(true))

export function updateZone(zone = moment.tz.guess(true)) {
  moment.tz.setDefault(zone)
  updateTimeZone()
}

export default moment
