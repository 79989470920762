import moment from '@/helpers/useMoment'

export const getTimeByMS = (ms = 0) => {
  const duration = moment.duration(ms)
  return {
    negative: ms < 0,
    hours: Math.abs(Math.trunc(duration.asHours())),
    minutes: Math.abs(duration.minutes()),
    seconds: Math.abs(duration.seconds()),
  }
}

export const formatTimeByMS = (ms = 0, full = false) => {
  const time = getTimeByMS(ms)
  if (!full) {
    return `${time.negative ? '-' : ''}${time.hours}h/${time.minutes}m`
  } else {
    return `${time.negative ? '-' : ''}${time.hours}h/${time.minutes}m/${
      time.seconds
    }s`
  }
}
export const timerCounterFormatByMS = (ms = 0) => {
  const time = getTimeByMS(ms)
  return `${time.negative ? '-' : ''}${String(time.hours).padStart(
    2,
    '0'
  )}:${String(time.minutes).padStart(2, '0')}:${String(time.seconds).padStart(
    2,
    '0'
  )}`
}
