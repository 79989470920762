<template>
  <div :style="style" class="editor-for-doc">
    <editor-menu-bubble
      :editor="editor"
      :keep-in-bounds="keepInBounds"
      v-slot="{ commands, isActive, menu }"
    >
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <button
          @click.prevent="commands.bold"
          :class="{ _active: isActive.bold() }"
          class="editor-for-doc__btn reset-button"
        >
          <BaseSvg name="ico-editor-bold" />
        </button>
        <button
          @click.prevent="commands.italic"
          :class="{ _active: isActive.italic() }"
          class="editor-for-doc__btn reset-button"
        >
          <BaseSvg name="ico-editor-italic" />
        </button>
      </div>
    </editor-menu-bubble>

    <editor-content class="editor-for-doc__content" :editor="editor" />

    <input :value="result" autocomplete="off" type="hidden" data-result />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBubble,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    fontSize: {
      type: Number,
      default: 12,
    },
  },
  data(vm) {
    return {
      result: vm.value,
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: vm.value,
        onUpdate: this.onUpdate,
        onInit: this.onInit,
      }),
    }
  },
  computed: {
    style() {
      return {
        fontSize: this.fontSize + 'px',
        lineHeight: this.fontSize * 1.3 + 'px',
      }
    },
  },
  methods: {
    onUpdate(data) {
      let html = data.getHTML()
      html = html === '<p></p>' ? null : html

      this.result = html
      this.$emit('input', html)
    },
    onInit() {
      this.$nextTick(() => {
        let html = this.editor.getHTML()
        html = html === '<p></p>' ? null : html

        this.result = html
        this.$emit('input', html)
      })
    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
