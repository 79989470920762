import { axiosApi } from '@/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    items: [],
    pending: false,
  },
  mutations: {
    setProperty(state, [key, value]) {
      if (!Object.prototype.hasOwnProperty.call(state, key)) return
      state[key] = value
    },
  },
  actions: {
    reset({ commit }) {
      commit('setProperty', ['items', []])
    },
    load({ commit }) {
      commit('setProperty', ['pending', true])
      return axiosApi
        .get('/profile')
        .then(({ data }) => {
          commit('setProperty', [
            'items',
            data.data.map((item) => item.attributes),
          ])
          return data
        })
        .finally(() => {
          commit('setProperty', ['pending', false])
        })
    },
    deleteHandler({ commit, dispatch }, id) {
      Vue.prototype.$vAccept.open({
        title: 'Delete company?',
        onAccept: () => {
          commit('setProperty', ['pending', true])
          axiosApi
            .delete(`/profile/${id}/delete`)
            .then(() => {
              dispatch('load')
            })
            .catch(() => {
              commit('setProperty', ['pending', false])
              // Vue.prototype.$errorCatchDefault(e)
            })
        },
      })
    },
    setActive({ commit, dispatch }, id) {
      commit('setProperty', ['pending', true])
      axiosApi
        .post(`/set_active_company/${id}`)
        .then(({ data }) => {
          commit(
            'user/setProperty',
            ['company_profile', data.item.attributes],
            { root: true }
          )
          dispatch('load')
        })
        .catch(() => {
          commit('setProperty', ['pending', false])
          // Vue.prototype.$errorCatchDefault(e)
        })
    },
  },
}
